import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				About | Японська Кулінарна Академія: Смак Східного Сонця
			</title>
			<meta name={"description"} content={"Японська кухня - це мистецтво, яке бере свій початок із вікової традиції та багатої історії."} />
			<meta property={"og:title"} content={"About | Японська Кулінарна Академія: Смак Східного Сонця"} />
			<meta property={"og:description"} content={"Японська кухня - це мистецтво, яке бере свій початок із вікової традиції та багатої історії."} />
			<link rel={"shortcut icon"} href={"https://ritas-flet.net/img/5900160.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ritas-flet.net/img/5900160.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ritas-flet.net/img/5900160.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ritas-flet.net/img/5900160.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ritas-flet.net/img/5900160.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ritas-flet.net/img/5900160.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="100%" />
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="--color-darkL2"
				md-padding="48px 40px 48px 40px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--light"
					font="--headline2"
					lg-text-align="left"
					sm-font="--headline3"
				>
					Про Нас
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--light"
					font="--base"
					lg-text-align="left"
					md-margin="0px 0px 28px 0px"
				>
					Сьогодні наша компанія є лідером у галузі навчання японської кулінарії за межами Японії. Наші інструктори – досвідчені мистецтвознавці та кухарі, які із задоволенням діляться своїми знаннями та досвідом із нашими студентами.

Японська кухня - це мистецтво, яке бере свій початок із вікової традиції та багатої історії. В "Японській Кулінарній Академії: Смак Східного Сонця" ми вивчатимемо історію кожної страви, заглиблюючись у її коріння, пов'язані з давньою культурою і традиціями Японії. Ви дізнаєтеся, як мистецтво приготування суші, сашимі, рамена та інших японських страв розвивалося протягом століть і стало неймовірно важливою частиною японської та світової культури.

Наші курси доступні для всіх рівнів досвіду, від початківців до досвідчених кухарів, і включають не тільки уроки приготування, але й історичні відомості про походження кожної страви та її місце в японській культурі.
				</Text>
			</Box>
			<Box
				display="flex"
				width="60%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
				lg-order="-1"
			>
				<Image
					src="https://ritas-flet.net/img/sushi-set-with-gold-color-table_140725-5505.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					object-position="0% 70%"
					sm-max-height="500px"
					height="100%"
				/>
			</Box>
		</Section>
		<Components.Cta>
			<Override slot="text1">
				Приєднайтесь до нас в "Японській Кулінарній Академії: Смак Східного Сонця" і пориньте в дивовижний світ японської кухні, де кожен прийом їжі стане справжнім мистецтвом.
			</Override>
		</Components.Cta>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6523ace1e2dd3a0022204083"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});